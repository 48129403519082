<template>

    <!-- ============================ Page Title Start================================== -->
    <div class="page-title  bl-dark" >
        <div class="container">

        </div>
    </div>
    <!-- ============================ Page Title End ================================== -->

    <!-- ============================ Main Section Start ================================== -->
    <section class="gray-bg" style="padding-top:20px">
        <div class="container-fluid">
            <div class="row">
                <!-- Item Wrap Start -->
                <div class="col-lg-12 col-md-12 col-sm-12">
                    <div class="row" v-if="!compFeaturedJobOffers">
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <div class="_filt_tag786">
                                <base-spinner class="mx-3" ></base-spinner>
                                Loading .....
                            </div>
                        </div>
                    </div>
                    <div class="row" v-else>
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <!-- Filter Search -->
                            <div class="_filt_tag786">
                                <div class="form-group filter_group m-0">
                                    <div class="_tag780">
                                        {{ compFeaturedJobOffers.length }}
                                        <strong>{{ searchValue.q?`"${searchValue.q}"`:'' }}</strong>
                                        jobs found
                                    </div>
                                </div>
                                <div class="_tag785">
                                    <div class="_g78juy mx-1 d-none d-lg-block">
                                        <select class="form-control" v-model="searchType">
                                            <option value="">Job type</option>
                                            <option v-for="type in jobTypeOptions"
                                                    :key="type.value"
                                                    :value="type.value">
                                                {{ type.alias }}
                                             </option>
                                        </select>
                                    </div>
                                    <div class="_g78juy mx-1 d-none d-lg-block">
                                        <select class="form-control" v-model="searchSalary">
                                            <option value="">Salary Info</option>
                                            <option value="disclose_salary">Disclose Salary</option>
                                            <option value="request_salary">Quote Salary</option>
                                            <option value="none">Unavailable</option>
                                        </select>
                                    </div>
                                    <div class="_g78juy mx-1 d-none d-lg-block">
                                        <select class="form-control"  v-model="searchCategory">
                                            <option value="">Category</option>
                                            <option v-for="category in simpleInfo.jobCategory"
                                                    :key="category.value" :value="category.value"
                                             > {{ category.alias }}</option>
                                        </select>
                                    </div>
                                    <div class="_g78juy mx-1 d-none d-lg-block">
                                        <select class="form-control"  v-model="searchCountry">
                                            <option value="">Location</option>
                                            <option v-for="country in countryList"
                                                    :value="country.country_name"
                                                    :key="country.id"
                                            >
                                                {{country.country_alias}}
                                            </option>
                                        </select>
                                    </div>
                                    <a href="javascript:void(0);" style="margin-left:5px" onclick="openRightMenu()" class="eltio_buttons d-none float-right">
                                        <i class="bi bi-funnel"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">

                        <!-- Single Job List -->
                        <div class="col-lg-6 col-md-6 col-sm-12"  v-for="(job, index) in compFeaturedJobOffers" :key="job.id">
                            <div class="_jb_list72" style="background:#f5f5f5">
                                <div class="jobs-like bookmark">
                                    <label class="toggler toggler-danger">
                                        <input type="checkbox">
                                            <i class="text-primary bi tiny-font" :class="myBookmarks.includes(job.id)?'bi-heart-fill':'bi-heart'">
                                                {{ myBookmarks.includes(job.id)?'saved':'' }}
                                            </i>
                                    </label>
                                </div>
                                <div class="_jb_list72_flex">
                                    <div class="_jb_list72_first">
                                        <div class="_jb_list72_yhumb small-thumb">
                                            <img src="https://via.placeholder.com/100x100" class="img-fluid" alt="">
                                        </div>
                                    </div>
                                    <div class="_jb_list72_last">
                                        <div class="_times_jb">{{ job.username.substr(0,100) }}</div>
                                        <h4 class="_jb_title">
                                            <a href="javascript:void(0)" @click="updateJobDetails(job.id, index)" data-toggle="modal" data-target="#jobDetails">
                                                {{ job.title.substr(0,65) }}
                                                {{ job.title.length > 55 ? '....':''}}
                                            </a>
                                        </h4>
                                        <div class="_times_jb">{{ JSON.parse(job.job_location)['country'] }}, {{ JSON.parse(job.job_location)['city'] }}</div>
                                        <ul class="_requirement_lists">
                                            <li>
                                                <span class="_elios req_1 pl-0 text-primary">
                                                    {{ job.currency == 'n'?'&#8358;':'' }}
                                                    {{ job.currency == '$'?'$':'' }}
                                                    {{ job.currency == '£'?'£':'' }}
                                                    {{ job.currency == 'c'?'&#8373;':'' }}
                                                    {{ job.currency == 'e'?'&#8364;':'' }}
                                                    <i class="bi bi-credit-card-2-front pr-1" v-if="job.salary_status == 'none' ||
                                                        job.salary_status == 'request_salary' "></i>
                                                    {{ job.salary_status == "none" ||
                                                       job.salary_status == "request_salary" ?
                                                       'salary n/a ':job.salary.toLocaleString("en") + ' ' +  placeholderOptions[job.job_type]
                                                    }}
                                                </span>
                                            </li>
                                            <li>
                                                <span class="_elios req_1 pl-0 text-primary">
                                                    <i class="bi bi-stack pr-1"></i>
                                                    {{ job.job_type.replace('_','-') }}
                                                </span>
                                            </li>
                                            <li>
                                                <span class="_elios req_1 pl-0 text-warning">
                                                    <i class="bi bi-clock pr-1"></i>
                                                 {{ new Date(job.end_date).toLocaleDateString("en-US") }}
                                                </span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="_jb_list72_foot">
                                    <div class="_times_jb"></div>
                                </div>
                            </div>
                        </div>

                    </div>


                </div>

            </div>
        </div>
    </section>
    <!-- ============================ Main Section End ================================== -->

    <base-modal mId="jobDetails"  mBg="bg-bage" mSize="lg"  >
         <template #header-left-slot>
             <div class="cursor-pointer" >
                 <span @click="gotoNextJob('prevs')" class="mr-2 mt-2 p-1 col-lg-1 col-4 btn btn-outline-secondary rounded small-font" v-if="isAuthenticated">
                     <i class="bi " :class="exceedMinJobList?' bi-stop-circle':'bi-chevron-double-left'"></i> prevs
                  </span>

                 <span @click="gotoNextJob('next')"  class="mr-2 mt-2 p-1 col-lg-1 col-4 btn btn-outline-secondary  rounded small-font"  v-if="isAuthenticated" >
                     next <i class="bi bi-chevron-double-right" :class="exceedMaxJobList?' bi-stop-circle':'bi-chevron-double-right'"></i>
                 </span>

                 <span class="mr-2 mt-2 col-lg-2 col-4 p-1 btn btn-secondary  rounded small-font" style="color:#333;" v-if="!isAuthenticated" >
                    <a :href="coreUrl.accountUrl+'login?platform=jobs'">
                         <i class="bi bi-lock"></i> Login to apply
                    </a>
                 </span>



             </div>
         </template>
         <template #header-right-slot>
             <span data-dismiss="modal" aria-label="Close">
                 <i class="bi bi-x-lg"></i>
             </span>
         </template>
         <template #default>

             <job-details :jobContent="currentJobDetails"
                          :key="currentKey"
                          :applicationHistory="myApplications"
                          :bookmarkHistory="myBookmarks"
                          @emit-update-job-history="updateJobHistory"
                          v-if="currentJobDetails.length > 0" >
             </job-details>

         </template>
    </base-modal>
</template>

<script>
// @ is an alias to /src
import { ref,inject,computed } from 'vue';
import { useStore } from 'vuex';
import JobDetails from '@/components/job/JobDetails.vue';
import simpleData from '@/hooks/simpleData';

export default {
  name: "JobsList",
  components: {
      JobDetails
  },
  setup(){

      const axios = inject('axios');
      const coreUrl = inject('coreUrl');

      const store = useStore();

      // use the dispatch function to call an action that calls a mutation
      store.dispatch("globals/executeSetNavMenu",true);
      store.dispatch("globals/executeSetFooterMenu",true);

      // get the current status of client account
      const isAuthenticated = ref(store.getters["auth/getAuthAuthentication"].isAuthenticated);

      const contractType = {
          full_time:"bg-success",
          part_time:"bg-primary",
          consultancy:"bg-info",
          freelance:"bg-secondary"
      }

      const jobTypeOptions = [
          {value:"full_time",alias:"Full-time"},
          {value:"part_time",alias:"Part-time"},
          {value:"consultancy",alias:"Consultancy"},
          {value:"freelance",alias:"Freelance"},
          {value:"internship",alias:"Internship"},
          {value:"contract",alias:"Contract"},
          {value:"volunteer",alias:"Volunteer"}
      ];

      // set the validators function
      const simpleInfo = simpleData();

      const loadJobList = ref(false);
      const featuredJobOffers = ref([]);
      const myApplications = ref([]);
      const myBookmarks = ref([]);

      const urlSearchParams = new URLSearchParams(window.location.search);
      const searchValue = Object.fromEntries(urlSearchParams.entries());

      const params = { par_total: 10000 , order_type: 'desc' }
      // use axios to get the data
      axios.get(
          coreUrl.backendUrl+'get_simple_job_ad_list',
          {withCredentials: true , params: params }).then((response) =>{

          const result = response.data.data.job_ads;

          // use the dispatch function to call an action that calls a mutation
          // to set the data of for the job ad
          featuredJobOffers.value = result

          if(featuredJobOffers.value){
              loadJobList.value = true
          }

          myApplications.value = response.data.data.applications?JSON.parse(response.data.data.applications):[]
          myBookmarks.value = response.data.data.bookmarks?JSON.parse(response.data.data.bookmarks):[]


      }).catch((error)=>{
              console.log(error)
      })


      //get the data for country and cities
      const countryList = ref(false);
      const cityList = ref(false);

          // use axios to get the data
      axios.get(
              coreUrl.backendUrl+'get_location_data',
              {withCredentials: true}).then((response) =>{

              // return the data to result and update existing data variable
              const result = response.data.data;

              countryList.value = result.countryList;
              cityList.value = result.cityList;

          }).catch((error)=>{
              console.log(error)
          })

      const searchType = ref("");
      const searchSalary = ref("");
      const searchCategory = ref("");
      const searchCountry = ref("");

      const compFeaturedJobOffers = computed(function(){

          let finalFeaturedJobOffers = []

          // finalFeaturedJobOffers = featuredJobOffers.value.filter((item) => !myApplications.value.includes(item.id) )
          finalFeaturedJobOffers = featuredJobOffers.value.filter(function(item){
              console.log(searchValue.q)
              if(
                  (!myApplications.value.includes(item.id)) &&
                  (
                      (searchValue.q?item.job_type.toLowerCase().search(searchValue.q.toLowerCase()) >= 0:true ) ||
                      (searchValue.q?item.title.toLowerCase().search(searchValue.q.toLowerCase()) >= 0:true ) ||
                      (searchValue.q?JSON.parse(item.job_location)['country'].toLowerCase().search(searchValue.q.toLowerCase()) >= 0:true )
                  ) &&
                  (searchType.value?item.job_type.toLowerCase() === searchType.value.toLowerCase() : true ) &&
                  (searchSalary.value?item.salary_status.toLowerCase() === searchSalary.value.toLowerCase() : true ) &&
                  (searchCategory.value?item.category.toLowerCase() === searchCategory.value.toLowerCase() : true ) &&
                  (searchCountry.value?JSON.parse(item.job_location)['country'].toLowerCase() === searchCountry.value.toLowerCase() : true )
              ){
                  return true;
              }
               return false;
          })

          return finalFeaturedJobOffers;

      })


      const currentJobDetails = ref([]);
      const currentJobIndex = ref(0);
      const currentKey = ref(0);
      function updateJobDetails(id, index){
          currentJobDetails.value = featuredJobOffers.value.filter((item) => item.id === id);
          currentJobIndex.value = index;
          currentKey.value = id;
      }

      const exceedMaxJobList = ref(false);
      const exceedMinJobList = ref(false);
      function gotoNextJob(direction){
          if(direction == 'next')
          {
            let newIndex = +currentJobIndex.value + 1;
            let jobListLength =  compFeaturedJobOffers.value.length;
             if( newIndex < jobListLength ) {
                 console.log(newIndex)
                 console.log(jobListLength)
                 exceedMaxJobList.value = false;
                 exceedMinJobList.value = false;
                 let job_id = compFeaturedJobOffers.value[newIndex]['id']
                 currentJobDetails.value = featuredJobOffers.value.filter((item) => item.id === job_id);
                 currentJobIndex.value = newIndex;
                 currentKey.value = job_id;
             }else{
                 exceedMaxJobList.value = true;
             }
          }


          if(direction == 'prevs')
          {
            let newIndex = +currentJobIndex.value - 1;
            let jobListLength = -1;
             if( newIndex > jobListLength ) {
                 exceedMaxJobList.value = false;
                 exceedMinJobList.value = false;
                 let job_id = compFeaturedJobOffers.value[newIndex]['id']
                 currentJobDetails.value = featuredJobOffers.value.filter((item) => item.id === job_id);
                 currentJobIndex.value = newIndex;
                 currentKey.value = job_id;
             }else{
                 exceedMinJobList.value = true;
             }
          }

      }

      function updateJobHistory(history, data){
          if(history == 'applications'){
              myApplications.value = data
          }

          if(history == 'bookmarks'){
              myBookmarks.value = data
          }

      }


      const placeholderOptions = {
          full_time:"salary per annum",
          part_time:"salary per annum",
          consultancy:"consultancy fee",
          freelance:"freelance fee",
          internship:"salary per annum",
          contract:"contract fee",
          volunteer:"volunteer fee"
      }



      return {
          coreUrl,
          contractType,
          isAuthenticated,
          loadJobList,
          featuredJobOffers,
          compFeaturedJobOffers,
          currentJobDetails,
          currentKey,
          gotoNextJob,
          exceedMaxJobList,
          exceedMinJobList,
          updateJobDetails,
          updateJobHistory,
          myApplications,
          myBookmarks,
          placeholderOptions,
          jobTypeOptions,
          simpleInfo,
          countryList,
          cityList,
          searchType,
          searchSalary,
          searchCategory,
          searchCountry,
          searchValue
      }


  }
};
</script>

<style scoped>

.employer-brands ul li{
    list-style-type:none;
    display:inline;
}

</style>
