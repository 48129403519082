export default function simpleData(){

    const jobCategory = [
        {value:"engineering",alias:"Engineering"},
        {value:"health_care",alias:"Health care"},
        {value:"software_engineering",alias:"Software engineering"},
        {value:"humanitarian_ngo",alias:"Humanitarian / Ngo"},
        {value:"media",alias:"Media"},
        {value:"arts_and_crafts",alias:"Arts and Craft"},
        {value:"other",alias:"other"}
    ]

    return {
        jobCategory
    }
}
